/* Mobile view adjustments */
@media (max-width: 768px) {
    .container {
      flex-direction: column;
      align-items: center;
      width: 100%; /* Ensure the container spans full width */
    }
  
    .container > div {
      width: 100%; /* Make sections stretch across the screen */
      max-width: 95%; /* Keep a little padding from the edges of the screen */
      margin: 10px 0; /* Add vertical spacing between the sections */
      box-sizing: border-box; /* Ensure padding doesn't increase width */
      
    }
  }
  
  .product-container {
    width: 50%; /* Default width for larger screens */
    margin-left: 20px;

    position: relative;
}

@media (max-width: 768px) {
    .delivery-form, .product-container {
      width: 100% !important;
      margin-bottom: 20px;
    }
  }

.delivery-form {
    width: 50%; /* Default width for larger screens */
    padding: 20px;
}

@media (max-width: 768px) {
    .delivery-form {
        width: 100% !important; /* Increase the width for mobile view */
        padding: 10px; /* Optional: Adjust padding if needed */
        margin: 0 auto; /* Center the container on mobile */
        margin-top: 170px !important;
    }
}

/* Mobile styles - will apply when screen width is 768px or less */
@media screen and (max-width: 768px) {
    .flex-container {
        height: 280vh !important ;
        margin-top: -10px;
    }
}