/* BackgroundAnimation.css */

/* Main default background */
@keyframes bgAnim {
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

/* Croco Nutrition Background */
.nutrition-bg {
  background-image: url('/public/ffffff.png');
  background-position: center; /* Centers the background image */
  background-repeat: no-repeat; /* Prevents the background from repeating */
  width: 100%; /* Ensures it takes up full width */
  height: auto; /* Adjusts the height to fit the content */
  min-height: 100vh; /* Ensures it covers at least the full viewport height */
}


/* Croco Team Background */
.wear-bg {
  background-image: url('/public/bacck.jpg');
  background-size: contain; /* Ensures the image covers the entire div */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  width: 50%; /* Adjusts the div to full width */
  height: 100vh; /* Sets the div height to full viewport height */
  display: flex; /* Optional: centers any content within the div */
  align-items: center; /* Optional: vertically centers content */
  justify-content: center; /* Optional: horizontally centers content */
}

/* Croco Wear Background  url('https://img.freepik.com/premium-photo/beige-leather-background-texture-full-frame_136875-4001.jpg')*/
.team-bg {
  background-image:url('/public/blanc.avif');
  background-size: contain;
}


/* Croco Team Background */
.wearsq-bg {
  background-image: url('/public/bacck.jpg'); /* Path to your background image */
  background-size: contain; /* Ensures the image covers the entire div */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  width: 100%; /* Sets the div to full width */
  height: 100vh; /* Sets the div height to full viewport height */
  display: flex; /* Optional: centers any content within the div */
  align-items: center; /* Optional: vertically centers content */
  justify-content: center; /* Optional: horizontally centers content */
}


