.navbar {
  display: flex;
  justify-content: center;
  padding: 4px 12px;
  background-color: #000000dd;
  position: fixed;
  top: 52px;
  right: 0; /* Aligns navbar to the left */
  width: 89%; /* Default width for larger screens */
  z-index: 20;
  transition: background-color 0.3s ease-in-out;
  font-size: 15px;
  font-weight: bold;
}

/* Mobile view: Make the navbar 100% width */
@media (max-width: 768px) {
  .navbar {
    width: 100%;
  }
}



.new{
  display: flex;
  align-items: center;
}
.navbar-toggler-icon {
  background-image: none !important; /* Remove the default background image */
  color: white !important; /* Make the icon white */
  font-size: 24px; /* Set the icon size */
  content: '\2630'; /* Use the Unicode hamburger icon */
}

.navbar-toggler {
  border-color: rgb(255, 255, 255); /* Optional: make the border light */
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  background-color: transparent; /* No background color on hover/focus */
}


.navbar.scrolled {
  background-color: rgba(0, 0, 0, 0.67);
}

.navbar-brand {
  height: 100px; /* Adjusted for better scaling */
  width: auto;
}

.navbar-brand img {
  height: 130px; 
  width: 130px;
  margin-top: -20px; /* Moves the image up */
  margin-left: -30px; /* Moves the image to the left */
}


/* Search Bar */
.search-bar {
  flex-grow: 1;
  margin-left: 30px;
  display: none; /* Hidden by default for mobile */
}

.search-bar.show-search {
  display: flex; /* Show when toggled */
}

.search-bar form {
  display: flex;
  align-items: center;
  width: 100%;
}

.search-bar input {
  width: 100%;
  padding: 7px;
  border-radius: 0px;
  border: none;
  margin-right: 8px;
}

.search-bar button {
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  
}
svg{
  height: 35px;
  width: 35px;
  color: white;
}

.search-bar button:hover {
  transition: background-color 0.3s ease;
}

.navbar-nav {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.nav-link {
  color: white;
  font-family: 'Roboto';
  font-size: 19px;
  font-weight: bolder;
  margin-left: 20px;
  padding: 8px 12px;
  transition: color 0.3s ease;
  text-transform: uppercase; /* Add this line */
}



.nav-link:hover {
  color: #009F6B;
}

.navbar .dropdown-menu {
  background-color: #000000dd;
  padding: 15px 0;
  margin-top: 29px;
  border-radius: 4px;
}

.dropdown-menu .dropdown-item {
  color: rgb(255, 255, 255);
  font-size: 17px;
  padding: 8px 16px;
  font-family: 'Roboto';
}

.dropdown-menu .dropdown-item:hover {
  background-color: #009F6B;
}


.dropdown-submenu {
  padding-left: 16px;
}

.nav-item i.fa-cart-shopping {
  margin-right: 8px;
}



@media (max-width: 767px) {
  .new{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .search-bar {
    display: none;
  }

  .navbar-toggler {
    display: block;
  }

  .collapse {
    display: none;
  }

  .collapse.show {
    display: block;
  }
}

@media (min-width: 768px) {
  .navbar-nav {
    flex-direction: row;
  }

  .search-bar {
    display: block;
  }
}


.nav-item.dropdown .dropdown-menu.show {
  display: flex; /* Show when the 'show' class is added */
  opacity: 1; /* Fully visible */
  visibility: visible; /* Make visible */
  width: 1000px; /* Set width */
  margin-left: -600px; /* Adjust margin to shift it left */
  position: absolute; /* Maintain absolute positioning */
}



.fa-stack[data-count]:after {
  position: absolute;
  right: 0%;
  top: 0%;
  content: attr(data-count);
  font-size: 40%;
  padding: 0.6em;
  border-radius: 999px;
  line-height: 0.75em;
  color: white;
  background-color: #009F6B; /* Hardcoded red color */
  text-align: center;
  min-width: 2em;
  font-weight: bold;
  border-style: solid;
}

.fa-circle {
  color: #DF0000;
}

.red-cart {
  color: #DF0000;
  background: white;
}

/* Mobile view adjustments */
@media (max-width: 750px) {
  .navbar-nav {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center;   /* Center elements horizontally */
    width: 100%;           /* Take full width */
    gap: 10px;             /* Add spacing between items */
  }

  .navbar-collapse {
    text-align: center; 
    margin-left: -30px;/* Center text alignment */
  }

  .navbar-toggler {
    margin-left: auto; /* Keep the toggler aligned on the right */
  }
}

.navbar-nav .nav-item .nav-link {
  color: rgb(255, 255, 255); /* Default color */
  transition: color 0.3s ease-in-out;
}

.navbar-nav .nav-item .nav-link:focus,
.navbar-nav .nav-item .nav-link.active {
  color: #009F6B !important; /* Keeps it white when selected */
  background-color: rgba(0, 0, 0, 0); /* Optional: Adds contrast */
  border-radius: 5px; /* Optional: Makes it look better */
}






