section {
    padding-top: 180px; /* Adjust the value according to the height of your navbar */
}

.carousel-control-prev {
    left: -110px; /* Adjust the value as needed to move it more to the left */
}

.carousel-control-next {
    right: -110px; /* Adjust the value as needed to move it more to the right */
}
.carousel-control-prev.image-control {
    left: 20px;
}

.carousel-control-next.image-control {
    right: 20px;
}

.carousel-inner {
    overflow:visible; /* Allow overflow for the inner carousel */
  }


  
@media (max-width:768px) {
   .fares{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
   } 
   .col-4,.col-8{
    width: 100%;
   }
}
.product-tit{
    font-family: 'Roboto';
}

/* General Styles */
@media (max-width: 768px) {
    /* Remove any margin or padding that may be causing the issue */
    body, html {
        margin: 0;
        padding: 0;
        overflow-x: hidden; /* Ensure no horizontal scrolling */
    }

    /* If there's any container or row that's causing the issue */
    .container, .row {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
    }

    /* Make sure all sections take full width and avoid overflow */
    .carousel, .product-info, .description, .flavor-selector, .quantity-expiry {
        width: 100%;
        box-sizing: border-box; /* Prevents padding from causing overflow */
    }

    /* Ensuring content is centered */
    .container {
        padding: 10px; /* Optional: Add padding to container for mobile */
    }

    /* Adjust column width for mobile */
    .col-12.col-md-6 {
        width: 100%;
        padding: 0;
    }

    /* Adjust the carousel controls for mobile */
    .carousel-control-prev, .carousel-control-next {
        width: 50px;
        height: 50px;
    }

    /* Fix the body overflow if there's an element causing horizontal scroll */
    * {
        box-sizing: border-box;
    }

    /* Adjust navbar or header padding if it's contributing to the space */
    .navbar {
        padding-left: 0;
        padding-right: 0;
    }

    /* Adjust any other margins or paddings that might be misaligned */
    .product-image, .product-title, .price {
        margin-left: 0;
        margin-right: 0;
    }
}

/* For smaller devices (max-width 576px) */
@media (max-width: 576px) {
    .product-title h3 {
        font-size: 22px;
    }

    .price {
        font-size: 18px;
    }

    .btn {
        padding: 8px;
        font-size: 14px;
    }

    .description img {
        width: 100%;
        max-width: 100%;
    }
}


