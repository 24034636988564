/* ProfilePage.css */

.profile-page {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
  
    color: #ffffff;
    height: 170vh;
}

.text-section {
    width: 50%;
    padding: 20px;
}

.text-section h1 {
    font-size: 2em;
    font-weight: bold;
    color: #ffffff;
}

.text-section h2 {
    font-size: 1.2em;
    font-weight: normal;
    color: #5dde5d;
}

.text-section h3 {
    font-size: 1.1em;
    font-weight: bold;
    margin-top: 20px;
    color: #ffffff;
}

.text-section p {
    font-size: 1em;
    line-height: 1.6;
    color: #cccccc;
    margin-top: 10px;
}

.image-section {
    width: 50%;
    display: flex;
    justify-content: center;
}

.image-section img {
    max-width: 75%;
    height: 10%;
   

}

